<template id="modal-template">
    <transition v-if="show" name="modal" appear>
        <div class="modal-container">
            <div class="modal-wrapper">
                <div class="modal modal--7">
                    <div class="modal__head">
                        <h1 class="modal__title">쿠폰사용</h1>
                        <button class="modal__close" type="button" @click="close">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <path d="M0.222,1.636 L1.636,0.221 L15.778,14.363 L14.364,15.778 L0.222,1.636 Z"/>
                                <path d="M14.364,0.221 L15.778,1.636 L1.636,15.778 L0.222,14.363 L14.364,0.221 Z"/>
                            </svg>
                        </button>
                    </div>
                    <div class="modal__body">
                        <div class="use-coupon-container">
                            <ul class="use-coupon__lists">
                                <li v-for="coupon in coupons" class="use-coupon__list">
                                    <label class="check">
                                        <input v-model="selected" class="input" type="checkbox" name="coupons" :value="coupon">
                                        <i class="icon"></i>
                                        <span class="text">{{ coupon.name }}</span>
                                        <span class="num"><em>{{ coupon.amount.format() }}</em>{{ coupon.unit }}</span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="modal__foot modal__foot--border">
                        <div class="modal__buttons modal__buttons--narrow">
                            <button class="button button--border-primary" type="button" @click="close"><span>취소하기</span></button>
                            <button class="button button--primary" type="button" @click="input(); close();"><span>쿠폰적용</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default{
    props: {
        value: { type: Array, default: null },
        carts: { type: Array, default: null },
        deliveryPrice: { type: Number, default: 0 }
    },
    data: () => {
        return {
            show: false,
            selected: [],

            coupons: []
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                await this.getMyCoupons();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },
        input(){
            this.$emit('input', this.selected);
        },
        open(){
            this.show = true;
            this.selected = [].concat(this.value);
            document.querySelector('html').setAttribute('data-modal', 'opened');
        },
        close(){
            this.show = false;
			document.querySelector('html').removeAttribute('data-modal');
        },

        getMyCoupons(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get("/api/v1/me/coupons", {
                        headers: {
                            skip: 0,
                            limit: 0
                        },
                    });

                    this.coupons = res.data.coupons.filter((coupon) => {
                        // 1. 이미 사용된 쿠폰 체크
                        if(coupon._order) return false;
                        // 1. 사용기간 체크
                        if(!coupon.expiration.period || (coupon.expiration.period && coupon.expiration.startDate <= Date.now().toDate() && Date.now().toDate() <= coupon.expiration.endDate)){
                            // 2. 최소 사용금액 체크
                            switch(coupon.type){
                                case "product": {
                                    var sum = this.carts.reduce((sum, cart) => {
                                        if(cart.product._id == coupon._product){
                                            return sum + (cart.salePrice * cart.amount);
                                        }
                                        return sum;
                                    }, 0);

                                    if(!coupon.minPrice || coupon.minPrice < sum){
                                        return true;
                                    }
                                    break;
                                }
                                case "order": {
                                    var sum = this.carts.reduce((sum, cart) => {
                                        return sum + (cart.salePrice * cart.amount);
                                    }, 0);

                                    if(!coupon.minPrice || coupon.minPrice < sum){
                                        return true;
                                    }
                                    break;
                                }
                                case "category": {
                                    var sum = this.carts.reduce((sum, cart) => {
                                        if(cart.product._category == coupon._category){
                                            return sum + (cart.salePrice * cart.amount);
                                        }
                                        return sum;
                                    }, 0);

                                    if(!coupon.minPrice || coupon.minPrice < sum){
                                        return true;
                                    }
                                    break;
                                }
                                case "delivery": {
                                    if(this.deliveryPrice) return true;
                                }
                            }
                        }
                        return false;
                    });

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        }
    },
}
</script>
